"use client";

import type { ProductPricesWithDiscount } from "@frend-digital/centra/types/product";
import clsx from "clsx";

import useLocation from "@/lib/centra/hooks/useLocation";
import usePriceList from "@/lib/centra/hooks/useSelection/cart/usePriceList";

import Skeleton from "@/components/ui/Skeleton";
import { useSelection } from "@/lib/centra/hooks/useSelection";
import styles from "./index.module.css";

const Price = ({
  prices,
  className,
  isSoldOut,
  isSalePrice,
}: {
  prices?: ProductPricesWithDiscount | ProductPricesWithDiscount[];
  className?: string;
  isSoldOut?: boolean;
  isSalePrice?: boolean;
}) => {
  const { isLoading, isInitialLoading, cart } = useSelection();

  const priceList = usePriceList(prices);

  const location = useLocation();

  const isDiscounted = priceList.productOnSale;

  if (isLoading || isInitialLoading || typeof cart.priceList === "undefined")
    return <PriceSkeleton />;

  if (isDiscounted)
    return (
      <div className={clsx(className, styles.discounted__wrapper)}>
        <span className={styles.price__after__discount}>
          <span className="sr-only">
            {"Price after " + priceList.discountPercent + "% discount."}
          </span>
          <p>
            {priceList.priceAsNumber} {location.currency}
          </p>
        </span>

        {!isSalePrice && (
          <span className={styles.price__before__discount}>
            <span className="sr-only">{"Price before discount."}</span>
            <p className={styles.discount__number}>
              {priceList.priceBeforeDiscountAsNumber} {location.currency}{" "}
            </p>
          </span>
        )}
      </div>
    );
  return (
    <span
      data-active="1"
      className={clsx(
        className,
        isSoldOut && styles.soldOut,
        isSalePrice && styles.isSalePrice,
      )}
    >
      {priceList?.price || (
        <span aria-hidden style={{ visibility: "hidden" }}>
          0
        </span>
      )}
    </span>
  );
};

export default Price;

export const PriceSkeleton = () => {
  return <Skeleton style={{ width: "70px" }} height="16px" />;
};
